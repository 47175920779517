import moment from 'moment';

export const mountFilter = filter => {
	const { search } = filter || { search: '' };
	let filters = {
		search      : search || undefined,
		createdDate : {
			from : where.startDate( filter?.dateCad ),
			to   : where.endDate( filter?.dateCad )
		},
		closedDealDate: {
			from : where.startDateClosedDeal( filter?.dateDeal ),
			to   : where.endDateClosedDeal( filter?.dateDeal )
		},

		dealLimitDate: {
			from : where.startDealLimitDate( filter?.dateLimitDeal ),
			to   : where.endDealLimitDate( filter?.dateLimitDeal )
		},
		dateLastModifiedDealStatus: {
			from : where.startDateLastModifiedDealStatus( filter?.dateLastModifiedDealStatus ),
			to   : where.endDateLastModifiedDealStatus( filter?.dateLastModifiedDealStatus )
		},
		agreementProposalPetitionDate: {
			from : where.startDealLimitDate( filter?.agreementProposalPetitionDate ),
			to   : where.endDealLimitDate( filter?.agreementProposalPetitionDate )
		},
		caseProcessualState         : where.processualState( filter?.select ),
		caseStatus                  : where.status( filter?.select ),
		dealStatus                  : where.selectDealStatus( filter?.select ),
		clientName                  : where.selectClient( filter?.select ),
		groupName                   : where.selectGroup( filter?.select ),
		task                        : where.selectTask( filter?.select ),
		caseType                    : where.selectCaseType( filter?.select ),
		state                       : where.selectStates( filter?.select ),
		negotiators                 : where.selectNegotiator( filter?.select ),
		isActive                    : filter?.active ?? true,
		alreadySentWhatsappProposal : filter?.alreadySentWhatsappProposal,
		negotiateInAf              	: filter?.negotiateInAf,
	};

	return { ...filters };
};

const where = {
	selectClient: function ( filter ) {
		if ( filter?.clientName ) {
			const clientName = [
				...new Set( filter.clientName.options.map( opt => opt.id ) )
			];

			if ( clientName.length > 0 ) {
				return clientName;
			}
		}
		return undefined;
	},
	selectNegotiator: function ( filter ) {
		if( filter?.negotiators ) {
			const negotiators = [ ...new Set( filter.negotiators.options.map( opt => opt.id ) ) ];
			if ( negotiators.length > 0 ) {
				return negotiators;
			}
		}
		return undefined;
	},
	selectGroup: function ( filter ) {
		if ( !filter?.groupName ) {
			return undefined;
		}

		const groupName = [
			...new Set( filter.groupName.options.map( opt => opt.id ) )
		];

		if ( groupName.length > 0 ) {
			return groupName;
		}
	},

	selectDealStatus: function ( filter ) {
		if ( !filter?.dealStatus ) {
			return undefined;
		}
		const dealStatus = [
			...new Set( filter.dealStatus.options.map( opt => opt.id ) )
		];

		if ( dealStatus.length > 0 ) {
			return dealStatus;
		}
	},

	processualState: function ( filter ) {
		if ( !filter?.processualState ) {
			return undefined;
		}
		const processualState = [
			...new Set( filter.processualState.options.map( opt => opt.name ) )
		];

		if ( processualState.length > 0 ) {
			return processualState;
		}
	},
	status: function ( filter ) {
		if ( !filter?.status ) {
			return undefined;
		}
		const status = [ ...new Set( filter.status.options.map( opt => opt.id ) ) ];

		if ( status.length > 0 ) {
			return status;
		}
	},

	statusSingle: function ( filter ) {
		if ( filter && filter.length > 0 ) {
			return filter;
		}
		return undefined;
	},

	selectTask: function ( filter ) {
		if ( filter?.tasks ) {
			const tasks = [ ...new Set( filter.tasks.options.map( opt => opt.name ) ) ];

			if ( tasks.length > 0 ) {
				return tasks;
			}
		}
		return undefined;
	},

	selectCaseType: function ( filter ) {
		if ( filter?.caseType ) {
			const caseType = [
				...new Set( filter.caseType.options.map( opt => opt.name ) )
			];

			if ( caseType.length > 0 ) {
				return caseType;
			}
		}
		return undefined;
	},

	selectStates: function ( filter ) {
		if ( filter?.states ) {
			const states = filter.states.options.map( item => item.name );
			return states;
		}

		return undefined;
	},

	startDateClosedDeal: function ( filter ) {
		if ( filter && filter.length > 0 ) {
			if ( filter[0] ) {
				const dateStart = new Date( filter[0] );

				return moment( dateStart, 'DD/MM/YYYY' ).format( 'YYYY-MM-DD' );
			}
		}
		return undefined;
	},

	endDateClosedDeal: function ( filter ) {
		if ( filter && filter.length > 0 ) {
			if ( filter[1] ) {
				const dateEnd = new Date( filter[1] );

				return moment( dateEnd, 'DD/MM/YYYY' ).format( 'YYYY-MM-DD' );
			}
		}
		return undefined;
	},

	startDealLimitDate: function ( filter ) {
		if ( filter && filter.length > 0 ) {
			if ( filter[0] ) {
				const startDate = new Date( filter[0] );

				return moment( startDate, 'YYYY-MM-DD' ).format( 'YYYY-MM-DD' );
			}
		}
		return undefined;
	},

	startDateLastModifiedDealStatus: function ( filter ) {
		if ( filter && filter.length > 0 ) {
			if ( filter[0] ) {
				const startDate = new Date( filter[0] );

				return moment( startDate, 'YYYY-MM-DD' ).format( 'YYYY-MM-DD' );
			}
		}
		return undefined;
	},
	endDateLastModifiedDealStatus: function ( filter ) {
		if ( filter && filter.length > 0 ) {
			if ( filter[1] ) {
				const endDate = new Date( filter[1] );

				return moment( endDate, 'YYYY-MM-DD' ).format( 'YYYY-MM-DD' );
			}
		}
		return undefined;
	},
	endDealLimitDate: function ( filter ) {
		if ( filter && filter.length > 0 ) {
			if ( filter[1] ) {
				const endDate = new Date( filter[1] );

				return moment( endDate, 'YYYY-MM-DD' ).format( 'YYYY-MM-DD' );
			}
		}
		return undefined;
	},
	startAgreementProposalPetitionDate: function ( filter ) {
		if ( filter && filter.length > 0 ) {
			if ( filter[0] ) {
				const startDate = new Date( filter[0] );

				return moment( startDate, 'YYYY-MM-DD' ).format( 'YYYY-MM-DD' );
			}
		}
		return undefined;
	},

	endAgreementProposalPetitionDate: function ( filter ) {
		if ( filter && filter.length > 0 ) {
			if ( filter[1] ) {
				const endDate = new Date( filter[1] );

				return moment( endDate, 'YYYY-MM-DD' ).format( 'YYYY-MM-DD' );
			}
		}
		return undefined;
	},

	startDate: function ( filter ) {
		if ( filter && filter.length > 0 ) {
			if ( filter[1] ) {
				const startDate = new Date( filter[0] );

				return moment( startDate, 'YYYY-MM-DD' ).format( 'YYYY-MM-DD' );
			}
		}
		return undefined;
	},

	endDate: function ( filter ) {
		if ( filter && filter.length > 0 ) {
			if ( filter[1] ) {
				const endDate = new Date( filter[1] );

				return moment( endDate, 'YYYY-MM-DD' ).format( 'YYYY-MM-DD' );
			}
		}
		return undefined;
	}
};
