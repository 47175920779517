import axios from 'axios';
import { verifyEnvUrl } from '../../util/verifyEnvUrl';

const PROD_CASE_DETAILS_URL = 'https://api.acordofechado.com.br/case-details';
const DEV_CASE_DETAILS_URL = 'https://us-central1-acordo-fechado-dev.cloudfunctions.net/caseDetailsFunctionDev';
//const DEV_CASE_DETAILS_URL = 'http://localhost:8080';

const caseDetailsApi = axios.create( {
	baseURL: verifyEnvUrl( PROD_CASE_DETAILS_URL, DEV_CASE_DETAILS_URL ),
} );

caseDetailsApi.interceptors.request.use( async config => {
	const token = localStorage.getItem( 'af-token' );
	if ( token ) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
} );

export default caseDetailsApi;
